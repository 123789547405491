import React from 'react'
import Lottie from 'react-lottie';
import { Link } from "gatsby"
import { BookCallButton } from "../common/book_call"
import { Brands } from './brands'
import animationData from '../../assets/lotties/92812-business-presentation.json';
import styles from '../layouts/page_layout.module.css'

export const HomeBanner = () => {
  const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <section className="py-0 md:pb-8 pt-0 lg:pt-8 w-screen h-auto lg:h-screen banner_back relative">
      <div className={`${styles.main_layout} banner_content py-2 md:pt-6 px-4 md:px-12 lg:px-16 mx-auto w-full bg-white rounded-lg shadow-none lg:shadow-outer flex flex-col md:flex-row items-center`}>
        <div className="w-full md:w-1/2 p-4 mb-4">
          <h1 className="text-4-5xl md:text-5xl xl:text-6xl font-bold leading-tight mb-4">
            Hello. I'm Aavgeen.
          </h1>
          <h2 className="text-l md:text-xl xl:text-2xl font-semibold text-black max-w-xs md:max-w-lg w-full leading-tight mb-6">
            I'm an Engineering Manager with over 7 years of extensive product experience and a keen eye for clean, and intuitive web & mobile experiences. I have a proven track record of building and leading both remote and non-remote teams to deliver high-quality results and achieve business goals.
          </h2>
          <Link to="#myprocess">
            <button className="rounded-lg shadow-sm hover:shadow-lg transition-all duration-300 bg-red text-white px-6 pb-4 pt-3 text-base md:text-xl font-bold leading-none outline-none">
              See my Process below
            </button>
          </Link>
          <BookCallButton className="ml-40 mb-4" />
        </div>
        <div className="w-full md:w-1/2 flex justify-center items-center p-4 pb-0 md:pb-16 mb-">
          <Lottie
            options={defaultAnimationOptions}
            width={600}
          />
        </div>
        {/* <Brands className="hidden lg:flex absolute bottom-0 right-0 mt-3" /> */}
      </div>
    </section>
  )
}
