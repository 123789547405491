import { Link } from 'gatsby'
import React from 'react'
import { LinkIcon } from '../../assets/icons'

export const BookCallButton = ({ className = '' }) => {
  return (
    <Link to='/schedule-call' className={`${className}`}>
      <button className="outline-none rounded-full h-32 md:h-40 w-32 md:w-40 mt-8 shadow-md hover:shadow-2xl transform 
                        -rotate-45 hover:rotate-0 transition-all ease-in duration-300 bg-yellow text-black p-4">
        <span className="text-xl md:text-3xl font-bold inline mr-2">Book a call</span><img className="h-4 md:h-6 inline mb-2" src={LinkIcon} alt="Schedule a call" />
      </button>
    </Link>
  )
}
