import { Link } from 'gatsby'
import React from 'react'
import { LinkIcon } from '../../assets/icons'

export const DropEmailButton = ({ className = '' }) => {
  return (
    <Link to='/contact-me' className={`${className}`}>
      <button className="outline-none rounded-full h-28 md:h-32 w-28 md:w-32 mt-8 shadow-md hover:shadow-2xl transform 
                        rotate-0 hover:-rotate-45 transition-all ease-in duration-300 bg-darkYellow text-black p-4">
        <span className="text-xl md:text-2xl font-bold inline mr-2">drop an email</span><img className="h-3 md:h-4 inline mb-2" src={LinkIcon} alt="Email" />
      </button>
    </Link>
  )
}
