import React from 'react'
import { Link } from 'gatsby'
import { AavgeenLogo } from '../../assets/icons'

export const AppHeader = () => {
  return (
    <header className="w-full px-4 md:px-12 lg:px-16 py-4 flex flex-row flex-wrap justify-center lg:justify-between bg-white shadow-sm">
      <Link to='/' className="mr-6 md:mr-0">
        <img className='h-10' src={AavgeenLogo} alt="Aavgeen Singh" />
      </Link>
      <nav className="flex flex-row justify-end md:ml-10">
        <Link to='/contact-me' className="mr-4 my-2">
          <button className="rounded-lg shadow-sm bg-yellow hover:shadow-lg hover:bg-white hover:text-red text-black px-2 md:px-6 pb-1 text-sm md:text-base font-semibold outline-none transition-all ease-in duration-300">
            Contact Me
          </button>
        </Link>
        <Link to='/schedule-call' className="my-2 ml-4">
          <button className="rounded-lg shadow-sm bg-red hover:shadow-lg hover:bg-black text-white hover:text-yellow px-2 md:px-6 pb-1 text-sm md:text-base font-semibold outline-none transition-all ease-in duration-300">
            Schedule a Call
          </button>
        </Link>
      </nav>
    </header>
  )
}
