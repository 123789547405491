import React from 'react'

export const Pitch = () => {
  return (
    <section className="w-full flex flex-col items-center mb-20">
      <div className="max-w-full md:max-w-xl text-left">
      <h3 className="text-4xl md:text-5xl text-red font-bold mt-16 md:mt-24 w-full text-left">
        Your website should turn visitors into engaged customers.
      </h3>
      <p className="text-2xl md:text-3xl font-semibold text-black max-w-xs md:max-w-lg w-full leading-tight mt-10">
        It’s like having a <span className="text-red text-2xl md:text-3xl font-semibold">top-performing sales rep</span> working 24 hours a day, 7 days a week.
      </p>
      {/* book consultation rectangle button */}
    </div>
    </section>
  )
}
