import * as React from "react"
import { PageLayout } from "../components/layouts/page_layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Config } from '../../config';
import { isEmail, isNullUndefinedOrEmpty } from "../utils/function";
import SEO from "../components/common/seo";
import { AavgeenLogo } from "../assets/icons";
import { navigate } from "gatsby";

const ContactMe = () => {
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const isFormValid = () => {
    if (isNullUndefinedOrEmpty(firstName) || !isEmail(email) || isNullUndefinedOrEmpty(message)) {
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    if (!isFormValid()) {
      return;
    }
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'first-name': firstName,
        'last-name': lastName,
        'email': email,
        'message': message,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <PageLayout>
      <SEO
        title="Contact | Aavgeen Singh"
        description="Contact for discussing you next idea."
        keywords={[
          'Product manager contact',
          'Developer contact',
          'top product managers contacts',
          'Product',
          'Digital marketing',
          'UI/UX',
          'branding',
          'mobile apps',
          'flutter',
          'web app',
          'website',
          'reactjs',
          'react',
          'cool portfolios',
          'product management services',
          'tailwind css',
          'tailwind',
        ]}
        lang="en"
        imageUrl={AavgeenLogo}
      />
      <h3 className="text-3xl md:text-4xl font-semibold text-red text-center w-full leading-tight my-6 md:my-10">
        Reach out to me
      </h3>
      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center mb-20">
        <article className="flex flex-col items-center justify-start flex-1">
          <h3 className="text-2xl font-semibold text-red text-center w-full leading-tight my-4 md:my-6">
            Hit me up
          </h3>
          <ul className="flex flex-col items-start">
            <li className="mx-3 mb-4">
              <a aria-label="Instagram" href={Config.social.instagram} target="_blank" rel="noopener noreferrer" className="hover:text-red flex justify-center items-center">
                <FontAwesomeIcon icon={faInstagram} className="h-8 w-8 mr-2" />
                <p>Instagram</p>
              </a>
            </li>
            <li className="mx-3 mb-4">
              <a aria-label="Linkedin" href={Config.social.linkedin} target="_blank" rel="noopener noreferrer" className="hover:text-red flex justify-center items-center">
                <FontAwesomeIcon icon={faLinkedinIn} className="h-8 w-8 mr-2" />
                <p>LinkedIn</p>
              </a>
            </li>
            <li className="mx-3 mb-4">
              <a aria-label="Twitter" href={Config.social.twitter} target="_blank" rel="noopener noreferrer" className="hover:text-red flex justify-center items-center">
                <FontAwesomeIcon icon={faTwitter} className="h-8 w-8 mr-2" />
                <p>Twitter</p>
              </a>
            </li>
            <li className="mx-3 mb-4 flex justify-center items-center">
              <FontAwesomeIcon icon={faGoogle} className="h-6 w-8 mr-2" />
              <p className="text-gray">{Config.social.email}</p>
            </li>
          </ul>
        </article>
        <h3 className="text-2xl font-semibold text-red text-center w-full leading-tight mt-10 flex-1 my-6 self-center">
          OR
        </h3>
        <article>
          <h3 className="text-2xl font-semibold text-red text-center w-full leading-tight my-4 md:my-6">
            Email me
          </h3>
          <form name="contact-aavi" method="post" data-netlify="true" data-netlify-honeypot="bot-field"
            className="w-full max-w-lg p-6 shadow-lg rounded-md bg-white"
            onSubmit={handleSubmit}
            action="/thanks/"
          >
            <input type="hidden" name="form-name" value="contact-aavi" />
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-darkYellow text-sm font-bold mb-2" htmlFor="grid-first-name">
                  First Name <span className="text-red">*</span>
                  <input
                    type="text"
                    name="first-name"
                    onChange={(e) => { setFirstName(e.target.value) }}
                    id="grid-first-name"
                    className="appearance-none block w-full p-2 mb-3 
                              leading-tight focus:outline-none bg-whiteBack rounded-md text-trueBlack"
                    placeholder="Wolf"
                  />
                </label>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-darkYellow text-sm font-bold mb-2" htmlFor="grid-last-name">
                  Last Name
                <input
                    type="text"
                    name="last-name"
                    onChange={(e) => { setLastName(e.target.value) }}
                    id="grid-last-name"
                    className="appearance-none block w-full p-2 mb-3 leading-tight 
                              focus:outline-none bg-whiteBack rounded-md text-trueBlack"
                    placeholder="Gupta"
                  />
                </label>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-darkYellow text-sm font-bold mb-2" htmlFor="grid-password">
                  Your E-mail <span className="text-red">*</span>
                  <input
                    type="email"
                    name="email"
                    onChange={(e) => { setEmail(e.target.value) }}
                    id="email"
                    className="appearance-none block w-full p-2 mb-3 leading-tight 
                                  focus:outline-none bg-whiteBack rounded-md text-trueBlack"
                  />
                </label>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-darkYellow text-sm font-bold mb-2" htmlFor="grid-password">
                  Message <span className="text-red">*</span>
                  <textarea
                    id="message"
                    name="message"
                    onChange={(e) => { setMessage(e.target.value) }}
                    className="no-resize appearance-none block w-full p-2 mb-3
                            leading-tight focus:outline-none bg-whiteBack h-48 resize-none rounded-md text-trueBlack"
                  />
                </label>
              </div>
            </div>
            <button
              className={`shadow rounded-md focus:shadow-outline 
                            focus:outline-none font-bold mt-3 pt-1 pb-2 px-6 outline-none transition-all ease-in duration-300 ${isFormValid() ? 'bg-yellow hover:bg-black hover:text-yellow text-black hover:shadow-lg' : 'bg-gray text-white cursor-not-allowed'}`}
              type="submit"
              disabled={!isFormValid()}
            >
              Send
            </button>
          </form>
        </article>
      </div>
    </PageLayout>
  )
}

export default ContactMe

