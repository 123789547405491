import React from 'react'
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/9408-creative-process.json';

const process = [
  {
    step: 'Explore & Frame',
    description: `First, we understand and boil down on our end users. Then, we put myself in our users's shoes in order to understand their needs. 
    We define as a team, the framework of the user experience and begin to formalize the user journey. We can even go into existing competing product, if any.`,
  },
  {
    step: 'Imagine',
    description: `We will generate several ideas to solve our problems. It will help you understand, 
    prioritize and focus on clear vision and plan, to help you achieve your goals. It helps teams conduct the strategic 
    planning process together. Achieving understanding, definition and agreement in one process vs. having to go back and forth for months.`,
  },
  {
    step: 'Brand Identity & Plan',
    description: 'We will design the Brand Indentiy, UI/UX and understand the legal, marketing, security and developement aspects of the product and come up with a product roadmap and timeline',
  },
  {
    step: 'Prototype',
    description: 'Once the design has been validated. We will be able to develop, launch and promote the prototype to a small group of users.',
  },
  {
    step: 'Deploy Test',
    description: 'We will analyze the prototype with small group of real users in order to optimize and validate the solution.',
  },
  {
    step: 'Build Product',
    description: 'We will analyze the prototype with real users in order to optimize and validate the solution.',
  },
  {
    step: 'Deploy Product',
    description: 'We will deploy the product to a wider audience and market it in full swing.',
  },
  {
    step: 'Repeat & Improve',
    description: 'We keep running A/B tests and other experiments to understand our customers and keep ideating on the new ways to solve problems.',
  },
]

export const MyProcess = () => {
  const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <section className="mb-16 text-center">
      <h2 id="myprocess" className="text-4xl md:text-4-5xl text-black underline font-bold mt-16 md:mt-24 w-full text-left">
        My Process
      </h2>
      <Lottie
        options={defaultAnimationOptions}
        width={400}
        speed={0.75}
      />
      {/* <p className="text-base text-red font-medium mt-2 mb-12 w-full text-left">
        Roughly same for most products but many products can involve more or less steps. We go deep into the first few and the last step.
      </p> */}
      <ul className="w-full text-left mt-8 md:mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 lg:gap-x-10 gap-y-4">
        {
          process.map((processStep, index) => (
            <li key={processStep.step} className="flex flex-col mb-6 md:mb-8 ease-in transform duration-300 shadow-md hover:shadow-xl px-6 md:px-10 py-6 rounded-lg cursor-pointer bg-white hover:scale-105 hover:skew-y-0 skew-y-3 hover:skew-x-0 -skew-x-3 hover:-translate-y-0 -translate-y-4">
              <h4 className="text-2xl md:text-3xl font-bold">{`${index + 1}. ${processStep.step}`}</h4>
              <p className="text-lg md:text-xl font-medium py-2">{processStep.description}</p>
            </li>
          ))
        }
      </ul>
      <h3 className="text-3xl md:text-4xl font-semibold text-red  max-w-xs md:max-w-lg w-full leading-tight mb-4 mx-auto mt-10">
        It all boils down to <span className="text-black text-3xl md:text-4xl font-semibold">understanding</span> your end user and the need that they havn't discovered yet.
      </h3>
    </section>
  )
}
