import { Link } from 'gatsby'
import React from 'react'
import { PageLayout } from '../components/layouts/page_layout'

const Thanks = () => {
  return (
    <PageLayout>
      <section className="h-screen w-full flex flex-col items-center px-6">
        <h1 className="text-2xl mt-20 md:mt-32 mb-3">Thank you!</h1>
        <p className="text-xl mb-6 text-center">I will get back to you within 4 hours, at max one working day.</p>
        <Link to="/" className="underline text-red font-semibold text-lg">⬅  Go back to Home </Link>
      </section>
    </PageLayout>
  )
}

export default Thanks
