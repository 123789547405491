import React from 'react'
import { AppFooter } from './app_footer'
import { AppHeader } from './app_header'
import "fontsource-league-spartan/300.css"
import "fontsource-league-spartan/400.css"
import "fontsource-league-spartan/500.css"
import "fontsource-league-spartan/600.css"
import "fontsource-league-spartan/700.css"
import "fontsource-league-spartan/800.css"
import "fontsource-league-spartan/900.css"
import "fontsource-league-spartan/index.css"

import '../../styles/index.css'

import styles from './page_layout.module.css'

export const PageLayout = ({ children, banner }) => {
  const Banner = banner === null || banner === undefined ? null : banner;
  return (
    <div className="max-w-screen flex flex-col items-center overflow-hidden">
      <AppHeader />
      {Banner && <Banner />}
      <div className={`${styles.main_layout} px-4 md:px-12 lg:px-16 mx-0`}>
        {children}
      </div>
      <AppFooter />
    </div>
  )
}

PageLayout.defaultProps = {
  banner: null
}
