import React from 'react'
import { FrontrowLogo, SagLogo, TWLogo, ShuddhiLogo, KrogerLogo } from "../../assets/icons"

export const Brands = ({className = ''}) => {
  const iconClassName = 'h-6 lg:h-8 mr-10 my-8';
  return (
    <section className={`w-full flex flex-col items-end ml-0 md:ml-10 my-2 ${className}`}>
      <div className="flex flex-row flex-wrap">
        <img src={FrontrowLogo}
          alt="logo" className={iconClassName} />
        <img src={KrogerLogo}
          alt="logo" className={`${iconClassName} h-10 lg:h-12 mt-5`} />
        <img src={TWLogo}
          alt="logo" className={iconClassName} />
        <img src={SagLogo}
          alt="logo" className={iconClassName} />
        <img src={ShuddhiLogo}
          alt="logo" className={iconClassName} />
      </div>
    </section>
  )
}
