import React from 'react'
import { BookCallButton } from '../common/book_call'
import { DropEmailButton } from '../common/drop_email'

export const Reachout = () => {
  return (
    <section className="w-full flex flex-col items-center mb-20">
      <div className="max-w-full md:max-w-xl text-left">
        <h3 className="text-4xl md:text-5xl text-red font-bold mt-16 md:mt-24 w-full">
          You got a cool project on mind? Let’s collaborate and create powerful
‍           <span className="text-black text-4xl md:text-5xl font-semibold">Product & Brand</span> for your startup.
        </h3>
        <p className="text-2xl md:text-3xl font-semibold text-black max-w-xs md:max-w-lg w-full leading-tight mt-10">
          In my spare time I love to listen podcasts, read books about entrepreneurship, create content and travel and visit interesting places.
        </p>
        <div className="flex flex-row flex-wrap items-center justify-center">
          <BookCallButton />
          <div className="w-16"></div>
          <DropEmailButton />
        </div>
      </div>
    </section>
  )
}

