import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Config } from '../../../config';

export const AppFooter = () => {
  return (
    <footer className="w-full px-4 md:px-12 lg:px-16 py-4 flex flex-col justify-center items-center bg-yellow text-black mt-6">
      <aside className="mt-3">
        <ul className="flex flex-row flex-wrap">
          <li className="mx-3"><a aria-label="Instagram" href={Config.social.instagram} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} className="h-8 w-8 p-1 hover:text-red" /></a></li>
          <li className="mx-3"><a aria-label="Linkedin" href={Config.social.linkedin} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn} className="h-8 w-8 p-1 hover:text-red" /></a></li>
          <li className="mx-3"><a aria-label="Twitter" href={Config.social.twitter} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} className="h-8 w-8 p-1 hover:text-red" /></a></li>
        </ul>
      </aside>
      <div className="flex flex-col md:flex-row justify-center items-center md:justify-between mt-2 w-full">
        <p className="mt-2">© {Config.author}</p>
        <p className="mt-2 text-sm">PWA made with <span role="img" aria-label="love"> 🖤  </span> in ReactJS</p>
      </div>
    </footer>
  )
}
