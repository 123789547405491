import * as React from "react"
import { Helmet } from "react-helmet"
import { AavgeenLogo } from "../assets/icons"
import SEO from "../components/common/seo"
import { PageLayout } from "../components/layouts/page_layout"

const ScheduleCall = () => {
  return (
    <PageLayout>
      <Helmet>
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
      </Helmet>
      <SEO
        title="Schedule call | Aavgeen Singh"
        description="Contact for discussing you next idea."
        keywords={[
          'Product manager contact',
          'Developer contact',
          'top product managers contacts',
          'Product',
          'Digital marketing',
          'UI/UX',
          'branding',
          'mobile apps',
          'flutter',
          'web app',
          'website',
          'reactjs',
          'react',
          'cool portfolios',
          'product management services',
          'tailwind css',
          'tailwind',
        ]}
        lang="en"
        imageUrl={AavgeenLogo}
      />
      <h3 className="text-4xl font-semibold text-red text-center w-full leading-tight mt-10">
        Select the time period which fits you.
      </h3>
      {/* <!-- Calendly inline widget begin --> */}
      <div className="calendly-inline-widget mt-1 lg:-mt-4" data-url="https://calendly.com/aavgeensingh" style={{ minWidth: '320px', height: '680px' }}></div>
      {/* <!-- Calendly inline widget end --> */}
    </PageLayout>
  )
}

export default ScheduleCall

