import React from 'react'
import { LinkIcon } from "../../assets/icons"
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/72908-business-growth-animation.json';

const organisations = [
  {
    name: 'Babbel GmBH',
    position: 'Engineering Manager(Growth)',
    description: `Leading, mentoring, and growing a high-performing full-stack team, spearheaded strategic projects like Stripe checkout migration, and collaborated with leadership to drive product-led growth through data-driven decisions. Expertise in technical leadership, cross-functional collaboration, and delivering impactful results. Eager to leverage these skills to contribute to your mission of revolutionizing e-learning.`,
    timeline: 'June 2023 - Present',
    links: [
      { name: 'Babbel Funnel', url: 'https://my.babbel.com/en/welcome/0' },
      { name: 'Babbel Prices and checkout', url: 'https://de.babbel.com/preise/ENG' },
      { name: 'Babbel Auth', url: 'https://my.babbel.com/en/authentication/login/email' },
      // { name: 'FrontRow IOS App', url: 'https://apps.apple.com/us/app/frontrow-learn-from-the-best/id1529245488' }
    ]
  },
  {
    name: 'FrontRow',
    position: 'Founding Member/Engineering manager',
    description: `Built a new generation of an E-learning platform for making your passions, a reality by providing never before taught courses from world-renowned experts.
                  Worked on the web and app platforms from the frontend. Aimed to lead the global marketplace for celebrity and expert-driven learning platforms.`,
    timeline: 'June 2020 - June 2023',
    links: [
      { name: 'FrontRow website', url: 'https://www.frontrow.co.in' },
      { name: 'FrontRow Android App', url: 'https://play.google.com/store/apps/details?id=io.connectcourses.app' },
      { name: 'FrontRow IOS App', url: 'https://apps.apple.com/us/app/frontrow-learn-from-the-best/id1529245488' }
    ]
  },
  {
    name: 'Thoughtworks',
    position: 'Product Engineer',
    description: `Built coupons (most visited page), fresh deliveries, products and customer education features/modalities for Kroger Digital(USA),
     one of the leading online and offline grocery chain in the Western world. Impreoved csutomer retention by optimizing the page loads and better copyrighting techniques`,
    timeline: 'March 2019 - June 2020',
    links: [
      { name: 'Kroger Coupons', url: 'https://www.kroger.com/cl/coupons/' },
      { name: 'Dillions Fresh', url: 'https://www.dillons.com/fresh/search' },
      { name: 'Customer Education', url: 'https://www.kroger.com/savingsoverview/?comingFrom=/cl/coupons/' }
    ]
  },
  {
    name: 'Software AG',
    position: 'Frontend Consultant',
    description: `Built in-house applications for Johnson & Johnson, a client of Software AG. Also, involved in POCs (Proof of concepts) for
     applications made for other clients like Standard charter Bank. Built high performance web apps for Germany HQ (Software AG). Contributed in 
     conceptualization and design of two web apps. (All webapps were internal products used by over 30,000 stakeholders)`,
    timeline: 'September 2017 - March 2019',
  },
  {
    name: 'Shuddhi',
    position: 'Freelance Engineer',
    description: `Designed and developed Android Application for Shuddhi. Design and Develop REST API and Database using Express JS. mongoose and MongoDB. 
    Came up with a lot of features during brainstorming sessions with the CEO. Developed part of their website using wordpress, HTML, CSS.`,
    timeline: 'July 2016 - August 2017',
    links: [
      { name: 'Shuddhi Website', url: 'https://shuddhi.ngo/' },
      { name: 'Shuddhi App', url: 'https://play.google.com/store/apps/details?id=com.mintavia.shuddhingo' },
    ]
  },
]

export const Portfolio = () => {
  const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <section className="mt-10">
      <h3 className="text-2xl md:text-4xl font-semibold text-red max-w-full md:max-w-lg text-center md:text-left w-full leading-tight">
        I help Startups plan and develop growth driven product and brand identity with clarity,
        meaning, and intention.
      </h3>
      <h2 className="text-4xl md:text-4-5xl  text-black underline font-bold mt-16 md:mt-24 w-full text-left">
        My Experience
      </h2>
      <h4 className="text-lg md:text-xl text-red font-semibold w-full text-left">
        (Highlights)
      </h4>
      <Lottie
        options={defaultAnimationOptions}
        width={400}
        speed={0.25}
      />
      <ul className="w-full text-left mt-8 md:mt-12 grid grid-cols-1 md:grid-cols-2 gap-x-4 lg:gap-x-8 gap-y-4">
        {
          organisations?.map((organisationsStep) => (
            <li key={organisationsStep.name} className="flex flex-col mb-6 md:mb-8 ease-in duration-300 shadow-md hover:shadow-xl px-6 py-6 rounded-lg bg-white">
              <h4 className="text-2xl md:text-3xl font-bold">{organisationsStep.name}</h4>
              <h5 className="text-lg md:text-xl text-gray font-semibold inline-block">{organisationsStep.position} (<span className="text-base text-gray">{organisationsStep.timeline}</span>)</h5>
              <p className="text-base my-4">{organisationsStep.description}</p>
              {organisationsStep?.links ? <h5 className="text-lg md:text-xl text-black font-semibold mb-4">Products built:</h5> : null}
              <ul>
                {organisationsStep?.links?.map((organisationLink) => (
                  <li className="mb-3" key={organisationLink.name}>
                    <a href={organisationLink.url} key={organisationLink.url} className="underline text-red font-semibold" target="_blank" rel="noreferrer">
                      {organisationLink.name} <img className="h-2 inline text-yellow ml-1" src={LinkIcon} alt="Go" />
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          ))
        }
      </ul>
      <h3 className="text-2xl md:text-3xl font-semibold text-red max-w-xs md:max-w-lg w-full leading-tight mx-auto mt-10 md:mt-20">
        In most cases, it’s the <span className="text-black text-2xl md:text-3xl font-semibold">UI/UX</span> and <span className="text-black text-3xl font-semibold">product execution</span> that decides your position between making or breaking a business.
      </h3>
    </section>
  )
}
